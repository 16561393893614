import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { getFrontPageUrl } from './api/Api';
import AppRouter from './router/AppRouter';
import { ReactSession } from 'react-client-session';
import MainLoader from './components/Loader/MainLoader';
export const UserContext = createContext(null);
function App() {
  const[loading,setLoading]  = useState(false)
  const[getInfo,setGetInfo] = useState({})
  const[getNav,setGetNav] = useState([])
  const[getSliders,setGetSliders] = useState([])
  const[getMainProducts,setGetMainProducts] = useState([])
  const[getExclusiveServices,setGetMainExclusiveServices] = useState([])
  
  ReactSession.setStoreType("localStorage");

  useEffect(()=>{
    const data = ReactSession.get("data");
    if(data){
      setGetInfo(data.microsite_info)
      setGetNav(data.main_nav)
      setGetSliders(data.slider)
      setGetMainProducts(data.main)
      setGetMainExclusiveServices(data.exclusive)
      
      setLoading(false)
      axios.get(getFrontPageUrl)
      .then((response)=>{
        ReactSession.set("data", response.data);
        setLoading(false)
        // window.location.reload();
      }) 
    }else{
      setLoading(true)
      axios.get(getFrontPageUrl)
      .then((response)=>{
        ReactSession.set("data", response.data);
        setLoading(false)
        window.location.reload();
      }).catch((err)=>{
      }) 
    }
    },[getFrontPageUrl])

    return (
      <div>
        {loading?<MainLoader />
        : 
        <UserContext.Provider value={{getInfo,getSliders,getNav,getMainProducts, getExclusiveServices}}>
          <BrowserRouter>
              <AppRouter />
          </BrowserRouter>
        </UserContext.Provider>
      }
      </div>
    );
}

export default App;
