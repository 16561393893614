import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";

import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link, useNavigate } from "react-router-dom";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { lineButtonHorizontal } from "../../effects";
import Nav from 'react-bootstrap/Nav';
import "../../asset/css/bootstrap.min.css";
import "../../asset/css/style.css";
import "../../asset/css/responsive.css";
import logo from '../../asset/image/logo.jpg'

import { UserContext } from '../../App';
import { baseUrl } from '../../api/Api';


const MobileHeader = ({info,mainNav}) =>{
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const data = useContext(UserContext);
  const { getInfo,getNav } = data;

  const logo = `${baseUrl}/${getInfo.entity_logo}`;
  const [stickyNav, setStickyNav] = useState(false);

    useEffect(() => {
        window.onscroll = () => {
          setStickyNav(window.pageYOffset === 0 ? false : true);
          return () => (window.onscroll = null);
        };
      }, []);

      const navigate = useNavigate()
      const handleNavigate = (catId,msId) =>{
        navigate(`/page-two/${catId}/${msId}`)
        window.location.reload()
      } 
  
     var dynamicMenues='';
     var otherMenu =''
     if(getNav){
      dynamicMenues =getNav.map((data,key)=>{
       if(data.name){
        var menu = ''
        if(data.children.length<1){
         return(
          <li key={data.uuid} onClick={(e)=>handleNavigate(data.id,getInfo.id)} ><Link>{data.name}</Link></li>
         )
          
        }else{
          return(
            <>
            <li key={data.uuid}><a>  {data.name} <i><FontAwesomeIcon icon={faCaretDown}/> </i>
                <span className="droppper" ></span></a>
                  <div className="sub_down">
                    {(data.children.map((data,key)=>{
                      return(
                        <li onClick={(e)=>handleNavigate(data.id,getInfo.id)} ><Link> {data.name} </Link></li>
                      )
                    }))}
                  </div>
            </li>
            </>
          )
        }
       }
      })
     }


    return(
        <Fragment>
            <Container>
                <div className="mobile_header d-block d-lg-none">
                   <div className="headerTop_wrapper">
                            <Link onClick={(e)=>window.location.href = '/'}>
                               <div className="logo">
                                   <img src={logo} alt="" />
                               </div>
                            </Link>
                       <div className="headerTop_right">
                            <i onClick={handleShow}> <FontAwesomeIcon icon={faBars}/> </i>
                       </div>   
                   </div>
                </div>
            </Container>
{/* OFcanvac  */}
    <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
               <div className="mobile_logo">
                   <Link onClick={(e)=>window.location.href = '/'} className="logo">
                       <img src={logo} alt="" />
                  </Link>
               </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <div className="mob_manu_wrapper">
                         <ul className="nav_list">
                              {dynamicMenues}
                          </ul> 
                     </div>
        </Offcanvas.Body>
    </Offcanvas>
        </Fragment>
    )
}
export default  MobileHeader;