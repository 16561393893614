import React, { Fragment, useContext } from "react";
import { Container ,Row ,Col} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { baseUrl } from "../../api/Api";
import { UserContext } from "../../App";
import icon1 from '../../asset/image/1.png'
import icon2 from '../../asset/image/2.png'
import icon3 from '../../asset/image/3.png'
import icon4 from '../../asset/image/4.png'
import shape from '../../asset/image/shapees.png'
import { imageTitleDetailsVerticle } from "../../effects/imageTitleDetailsVerticle";
const Service = () =>{
    const data = useContext(UserContext);
    const { getExclusiveServices } = data;
    

    var detailsFilter1 = ''
    var details1 = ''
    var detailsFilter2 = ''
    var details2 = ''
    var detailsFilter3 = ''
    var details3 = ''
    var detailsFilter4 = ''
    var details4 = ''

    if(getExclusiveServices[0]){
        detailsFilter1 = getExclusiveServices[0].body.replace(/<[^>]*>?/gm, '').slice(0,100)
        details1 = detailsFilter1.replace(/\&nbsp;/g, '');
    }
    if(getExclusiveServices[1]){
        detailsFilter2 = getExclusiveServices[1].body.replace(/<[^>]*>?/gm, '').slice(0,100)
        details2 = detailsFilter2.replace(/\&nbsp;/g, '');
    }
    if(getExclusiveServices[2]){
         detailsFilter3 = getExclusiveServices[2].body.replace(/<[^>]*>?/gm, '').slice(0,100)
         details3 = detailsFilter3.replace(/\&nbsp;/g, '');
    }
    if(getExclusiveServices[3]){

         detailsFilter4 = getExclusiveServices[3].body.replace(/<[^>]*>?/gm, '').slice(0,100)
         details4 = detailsFilter4.replace(/\&nbsp;/g, '');
    }

    const navigate = useNavigate()
    const handleNavigate = (uuid,title)=>{
        navigate(`/detail/${uuid}/${title}`)
        window.location.reload()
    }


    return(
        <Fragment>
           <div className="service">
              <Container>
                    <div className="section_heading">
                        Our Service
                    </div>
                <div className="services__inner">
                   <div class="services-shape-1">
                       <img src={shape} alt=""/>
                   </div>
                   {(getExclusiveServices.length>0)?<>
                    <Row>
                   <Col lg={3} md={6} sm={6}>
                   <Link onClick={(e)=>handleNavigate(getExclusiveServices[0].uuid,getExclusiveServices[0].title)} >
                       <div className="services__single">
                       {(getExclusiveServices.length>0) && <>
                        <div className="services__icon-box">
                                <div className="services__icon">
                                    <span className="icon-select"><img src={`${baseUrl}/${getExclusiveServices[0].featured_image}`} alt={getExclusiveServices[0].title}/></span>
                                </div>
                                <div className="services__count"></div>
                            </div>
                            <div className="services__content">
                                <h3>{getExclusiveServices[0].title}</h3>
                                <h4>{details1}</h4>
                            </div>
                       </>}
                        </div>
                        </Link>
                   </Col>
                   <Col lg={3} md={6} sm={6}>
                   <Link onClick={(e)=>handleNavigate(getExclusiveServices[1].uuid,getExclusiveServices[1].title)} >
                        <div className="services__single single2">
                        {(getExclusiveServices.length>1) && <>
                            <div className="services__icon-box">
                                <div className="services__icon">
                                    <span className="icon-select"><img src={`${baseUrl}/${getExclusiveServices[1].featured_image}`} alt={getExclusiveServices[1].title}/></span>
                                </div>
                                <div className="services__count"></div>
                            </div>
                            <div className="services__content">
                                <h3>{getExclusiveServices[1].title}</h3>
                                <h4>{details2}</h4>
                            </div>
                            </>}
                        </div>
                        </Link>
                   </Col>
                   <Col lg={3} md={6} sm={6}>
                   <Link onClick={(e)=>handleNavigate(getExclusiveServices[2].uuid,getExclusiveServices[2].title)} >
                        <div className="services__single single3">
                        {(getExclusiveServices.length>2) && <>
                            <div className="services__icon-box">
                                <div className="services__icon">
                                    <span className="icon-select"><img src={`${baseUrl}/${getExclusiveServices[2].featured_image}`} alt={getExclusiveServices[2].title}/></span>
                                </div>
                                <div className="services__count"></div>
                            </div>
                            <div className="services__content">
                                <h3>{getExclusiveServices[2].title}</h3> 
                                <h4>{details3}</h4>
                            </div>
                            </>}
                        </div>
                        </Link>
                   </Col>
                   <Col lg={3} md={6} sm={6}>
                   <Link onClick={(e)=>handleNavigate(getExclusiveServices[3].uuid,getExclusiveServices[3].title)} >
                         <div className="services__single">
                         {(getExclusiveServices.length>3) && <>
                            <div className="services__icon-box">
                                <div className="services__icon">
                                    <span className="icon-select"><img src={`${baseUrl}/${getExclusiveServices[3].featured_image}`} alt={getExclusiveServices[3].title}/></span>
                                </div>
                                <div className="services__count"></div>
                            </div>
                            <div className="services__content">
                                <h3>{getExclusiveServices[3].title}</h3>
                                <h4>{details4}</h4>
                            </div>
                            </>}
                        </div>
                        </Link>
                   </Col>
                   </Row>   
                   </>:<>
                   <div className="services__single">
                        <Row>
                            <Col lg={3} >
                                {imageTitleDetailsVerticle()}
                            </Col>
                            <Col lg={3} >
                                {imageTitleDetailsVerticle()}
                            </Col>
                            <Col lg={3} >
                                {imageTitleDetailsVerticle()}
                            </Col>
                            <Col lg={3} >
                                {imageTitleDetailsVerticle()}
                            </Col>
                        </Row>
                     </div>
                   </>}
                                    
                </div>
              </Container>
           </div>
        </Fragment>
    )
}
export default  Service;