import { faFacebook, faInstagram, faLinkedinIn, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faCaretRight, faChevronRight, faEnvelope, faLocation, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { baseUrl } from "../../api/Api";
import { UserContext } from "../../App";
import logo from '../../asset/image/logo.jpg'

const Footer = (props) =>{

    const data = useContext(UserContext);
    const { getInfo } = data;
  
    const logo = `${baseUrl}/${getInfo.entity_logo}`;

    return(
        <Fragment>
              <div className="footer">
                 <Container>
                   <div className="footer_wrapper">
                    <Row>
                        <Col lg={4}>
                           <div className="footer_wrap">
                               <div className="logo">
                                   <img src={logo} alt="" />
                               </div>
                               <div className="hints"> {getInfo.entity_details}</div>
                           </div>
                        </Col>
                        <Col lg={4}>
                            <div className="footer_wrap">
                                 <h3> Contact Us </h3>
                                 <h4> <FontAwesomeIcon icon={faPhone}/> {getInfo.entity_phone} </h4>
                                 <h4> <FontAwesomeIcon icon={faEnvelope}/> {getInfo.entity_email}  </h4>
                                 <div className="social_icon">
                                    <h6> Our Social Network :</h6>
                                    <ul>
                                        <li> <a href="/"> <i><FontAwesomeIcon icon={faFacebook}/> </i> </a> </li>
                                        <li> <a href="/"> <i className="tr"><FontAwesomeIcon icon={faTwitter}/> </i> </a> </li>
                                        <li> <a href="/"> <i className="link_d"> <FontAwesomeIcon icon={faLinkedinIn}/> </i> </a></li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="footer_wrap margin_R">
                                <h3> Important Link </h3>
                                 <ul>
                                    {(getInfo.website) && <li><a href={getInfo.website}> <i><FontAwesomeIcon icon={faChevronRight}/> </i> {getInfo.website} </a></li>}
                                    
                                 </ul>
                                <h4 className="mt-3"> <FontAwesomeIcon icon={faLocation}/> <span>Address :</span> {getInfo.address}</h4>
                            </div>
                        </Col>
                    </Row> 
                   </div>
                </Container> 
                <div className="copy_right">
                    <Container>
                        <div className="copyRight_wrapper">
                            <div className="copyright_left">
                               © All Rights Reserved by, <span>toamed.com</span>
                            </div>
                            <div className="copyright_right">
                            Terms & Conditions • Privacy© 
                            </div>
                        </div>
                    </Container>
                </div>    
              </div>
        </Fragment>
    )
}
export default  Footer;