import React, { Fragment  } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ProductListItem from "./ProductListItem";
import product1 from '../../asset/image/product1.jpg'
import { Link, useNavigate } from "react-router-dom";
import { baseUrl } from "../../api/Api";
import { imageTitleDetailsVerticle } from "../../effects/imageTitleDetailsVerticle";
const ProductListBody = ({posts}) =>{
    const navigate = useNavigate()
    const handleNavigate = (uuid,title)=>{
        navigate(`/detail/${uuid}/${title}`)
        window.location.reload()
    }
    
    return(
        <Fragment>
            <Container>
               <div className="productListing">
                    <div className="section_heading">
                        List Of Product
                    </div>
                    {(posts.length>0)?<>
                        <Row>
                            {posts.map((data,key)=>{
                                return(
                                    <Col lg={4}>
                                        <Link onClick={(e)=>handleNavigate(data.uuid,data.title)}>
                                            <div className="product_list_item">
                                                <div className="product_img">
                                                    {(data.featured_image)?<><img src={`${baseUrl}/${data.featured_image}`} alt={data.title} /></>:''}
                                                </div>
                                                <div className="product_item_text">
                                                    <h4>{data.title}</h4>
                                                </div>
                                            </div>
                                            </Link>
                                </Col>
                                )
                            })}
                        </Row>
                    </>:<>
                        <Row>
                            <Col lg={4} >
                                {imageTitleDetailsVerticle()}
                            </Col>
                            <Col lg={4} >
                                {imageTitleDetailsVerticle()}
                            </Col>
                            <Col lg={4} >
                                {imageTitleDetailsVerticle()}
                            </Col>
                        </Row>
                    </>}
                 
               </div>
            </Container>
        </Fragment>
    )
}
export default  ProductListBody;