import axios from 'axios'
import React, {Fragment, useCallback, useEffect, useState} from 'react'
import { getPostsByCatIdUrl } from '../api/Api'
import Footer from '../components/Footer/Footer'
import MainLoader from '../components/Loader/MainLoader'
import MobileHeader from '../components/MobileHeader/MobileHeader'
import ProductListBody from '../components/ProductListBody/ProductListBody'
import TopNaviation from '../components/TopNavigation/TopNaviation'
import useIsMountedRef from '../hooks/useIsMountedRef'

const ProductListPage = () =>{
  const[posts,setPosts] = useState([])
  window.scroll(0,0)
  const isMountedRef = useIsMountedRef();
  var URL=window.location.pathname;
  var arr=URL.split('/');
  const cat_id = arr[2]
  const ms_id = arr[3]

  const url = `${getPostsByCatIdUrl}/${cat_id}/${ms_id}`
  const getAllPosts = useCallback(async () => {
    try {
      const response = await axios.get(url);
        // console.log(response.data)
      if (isMountedRef.current) {
        setPosts(response.data.posts);
      }
    } catch (error) {
    }
  }, [useIsMountedRef]);

  useEffect(() => {
    getAllPosts();
  }, [getAllPosts]);

if(posts){
  return (
    <> 
    <MobileHeader/>
    <div className="header_two">
      <TopNaviation/>
    </div>
    <ProductListBody posts={posts}/>
    <Footer/>
   </> 
  )
}else{
  return(
    <MainLoader />
  )
}

  
}
export default ProductListPage