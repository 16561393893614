import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Col, Nav, Row, Tab, TabContainer } from "react-bootstrap";
import {  faHeart, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import product1 from '../../asset/image/product1.jpg'
import product2 from '../../asset/image/banner1.jpg'
import ProductListItem from "../ProductListBody/ProductListItem";
import { baseUrl, getSinglePostUrl } from "../../api/Api";
import useIsMountedRef from '../../hooks/useIsMountedRef'
import axios from "axios";
import MainLoader from "../Loader/MainLoader";
import parser from 'html-react-parser'
import { Link, useNavigate } from "react-router-dom";
const ProductDetails = () =>{
  const isMountedRef = useIsMountedRef();
  var URL=window.location.pathname;
  var arr=URL.split('/');
  const uuid = arr[2]

  const[singlePost,setSinglePost] = useState({})
  const url = `${getSinglePostUrl}/${uuid}`
  const getAllPosts = useCallback(async () => {
    try {
      const response = await axios.get(url);
      if (isMountedRef.current) {
        setSinglePost(response.data);
      }
    } catch (error) {
    }
  }, [useIsMountedRef]);

  useEffect(() => {
    getAllPosts();
  }, [getAllPosts]);

  const navigate = useNavigate()
  const handleNavigate = (uuid,title)=>{
      navigate(`/detail/${uuid}/${title}`)
      window.location.reload()
  }

  if(singlePost && Object.keys(singlePost).length>0){
    return(
      <Fragment>
          <Container>
                   <div className="ProductDetail">
                        <div className="product_info-1">
                            <Row>
                               <Col lg={5}>
                                  <TabContainer id="left-tabs-example" defaultActiveKey="first">
                                      <Row>
                                        <Col sm={12}>
                                          <Tab.Content>
                                            <Tab.Pane eventKey="first">
                                                <div className="productVisable_Img">
                                                  {(singlePost.posts.featured_image) && <img src={`${baseUrl}/${singlePost.posts.featured_image}`} alt={singlePost.posts.title} />}
                                                    
                                                </div>
                                            </Tab.Pane>
                                          </Tab.Content>
                                        </Col>
                                      </Row> 
                                  </TabContainer>
                               </Col>
                               <Col lg={7}>
                                 <div className="product_text"> 
                                    <div className="productCategory">{(singlePost.posts.category) && (singlePost.posts.category.name)}</div>
                                    <div className="productName"> {(singlePost.posts) && (singlePost.posts.title)} </div>
                                   <div className="product_detail_text">
                                   {(singlePost.posts)? <>{parser(singlePost.posts.body)}</>:<></>}
                                   </div>                                      
                                 </div>
                               </Col>
                            </Row>
                        </div>
                   </div>
                   <Row>
                    <h3>Popular Products</h3>
                    {(singlePost.popular) && singlePost.popular.slice(0,3).map((data,key)=>{
                      return(
                        <Col lg={4}> 
                            <Link onClick={(e)=>handleNavigate(data.uuid,data.title)} >
                            <div className="product_list_item">
                                <div className="product_img">
                                  {(data.featured_image) && <img src={`${baseUrl}/${data.featured_image}`} alt={data.title} />}
                                </div>
                                <div className="product_item_text">
                                    <h4>  {(data.title)?(data.title):""} </h4>
                                </div>
                            </div>
                          </Link>
                       </Col>
                      )
                    })}
                   </Row>
                   <Row className="mt-5">
                   <h3>Latest Products</h3>
                   {(singlePost.latest) && singlePost.popular.slice(0,3).map((data,key)=>{
                      return(
                        <Col lg={4}> 
                            <Link onClick={(e)=>handleNavigate(data.uuid,data.title)} >
                            <div className="product_list_item">
                                <div className="product_img">
                                  {(data.featured_image) && <img src={`${baseUrl}/${data.featured_image}`} alt={data.title} />}
                                </div>
                                <div className="product_item_text">
                                    <h4>  {(data.title)?(data.title):""} </h4>
                                </div>
                            </div>
                          </Link>
                       </Col>
                      )
                    })}
                   </Row>
            </Container>
      </Fragment>
  )
  }else{
    return(
      <MainLoader />
    )
  }
   
}
export default  ProductDetails;