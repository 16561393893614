
const baseUrl = 'https://icircles.app'


const getFrontPageUrl = `${baseUrl}/api/cms/frontpages/31?main_limit=4&exclusive_limit=4`
const getPostsByCatIdUrl= `${baseUrl}/api/cms/categorypages`
const getSinglePostUrl =  `${baseUrl}/api/cms/dynamiccontent`

const getSingleAuthorDetailsUrl =`${baseUrl}/api/littlemag/authors`
const  hitUrl = `${baseUrl}/api/cms/hits`


export {baseUrl,getFrontPageUrl,getPostsByCatIdUrl,getSinglePostUrl,getSingleAuthorDetailsUrl,hitUrl}

