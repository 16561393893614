import React, {Fragment} from 'react'
import Banner from '../components/Banner/Banner'
import Footer from '../components/Footer/Footer'
import Product from '../components/Product/Product'
import Service from '../components/Service/Service'
import TopNaviation from '../components/TopNavigation/TopNaviation'
import MobileHeader from '../components/MobileHeader/MobileHeader'

const HomePage = () =>{
  window.scroll(0,0)

  return (
    <> 
    <MobileHeader/>
    <TopNaviation/>
    <Banner/>
    <Product/>
    <Service/>
    <Footer/>
   </> 
  )
}
export default HomePage