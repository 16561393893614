import React, { Fragment, useContext, useEffect, useState } from 'react'
import Nav from 'react-bootstrap/Nav';
import "../../asset/css/bootstrap.min.css";
import "../../asset/css/style.css";
import "../../asset/css/responsive.css";
import { Link, NavLink, useNavigate } from 'react-router-dom';
import logo from '../../asset/image/logo.jpg'
import { faCaretDown, faEnvelope, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext } from '../../App';
import { baseUrl } from '../../api/Api';


const TopNaviation = (props) => {
  const data = useContext(UserContext);
  const { getInfo,getNav } = data;

  const logo = `${baseUrl}/${getInfo.entity_logo}`;
  const [stickyNav, setStickyNav] = useState(false);

    useEffect(() => {
        window.onscroll = () => {
          setStickyNav(window.pageYOffset === 0 ? false : true);
          return () => (window.onscroll = null);
        };
      }, []);

      const navigate = useNavigate()
      const handleNavigate = (catId,msId) =>{
        navigate(`/page-two/${catId}/${msId}`)
        window.location.reload()
      } 
  
     var dynamicMenues='';
     var otherMenu =''
     if(getNav){
      dynamicMenues =getNav.map((data,key)=>{
       if(data.name){
        var menu = ''
        if(data.children.length<1){
         return(
          <li key={data.uuid} onClick={(e)=>handleNavigate(data.id,getInfo.id)} ><Link>{data.name}</Link></li>
         )
          
        }else{
          return(
            <>
            <li key={data.uuid}><a>  {data.name} <i><FontAwesomeIcon icon={faCaretDown}/> </i>
                <span className="droppper" ></span></a>
                  <div className="sub_down">
                    {(data.children.map((data,key)=>{
                      return(
                        <li onClick={(e)=>handleNavigate(data.id,getInfo.id)} ><Link> {data.name} </Link></li>
                      )
                    }))}
                  </div>
            </li>
            </>
          )
        }
       }
      })
     }
  

return (
  <Fragment>
    <title>{props.title}</title>
        <header className='d-none d-lg-block'>
        <div className="header_top">
            <div className="container-fluid">
                <div className="headerTop_wrapper">
                    <div className="headerTop_left"> <FontAwesomeIcon icon={faLocationDot}/> Bangladesh </div>
                    <div className="headerTop_right"> 
                         <div className="text_1"> <FontAwesomeIcon icon={faEnvelope}/> {getInfo.entity_email}</div> 
                    </div>
                </div>
            </div>
        </div>
                                          
        <div className={` header_menu ${stickyNav ? 'HeaderSticky' : ''} }`}>
            <div className="container-fluid">
                  <div className="manu_wrapper">
                    <Link to="/" className="logo">
                       <img src={logo} alt="" />
                    </Link>
                         <ul className="nav_list">
                              {dynamicMenues}
                          </ul> 
                     </div>
                  </div>
             </div>
        </header>
  </Fragment>
)
}

export default TopNaviation