import React, { Fragment, useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../App";
import ProductItem from "./ProductItem";
import product1 from '../../asset/image/product1.jpg'
import { baseUrl } from "../../api/Api";
import { imageTitleDetailsVerticle } from "../../effects/imageTitleDetailsVerticle";

const Product = () =>{
    const data = useContext(UserContext);
    const { getMainProducts } = data;

    const navigate = useNavigate()
    const handleNavigate = (uuid,title)=>{
        navigate(`/detail/${uuid}/${title}`)
        window.location.reload()
    }
  
  

    return(
        <Fragment>
            <Container>
                <div className="product">
                    <div className="section_heading">
                        Our Product
                    </div>
                    {(getMainProducts.length>0)?<>
                        <div className="product_wrapper">
                        <Row>
                            {getMainProducts && getMainProducts.map((data,key)=>{
                                return(
                                    <Col lg={3} md={6} sm={6} key={data.uuid}>
                                      <Link onClick={(e)=>handleNavigate(data.uuid,data.title)} >
                                            <div className="product_item">
                                                <div className="product_img">
                                                {(data.featured_image)?<><img src={`${baseUrl}/${data.featured_image}`} alt={data.title} /></>:''}
                                                </div>
                                                <div className="product_item_text">
                                                    <h4>  {data.title} </h4>
                                                </div>
                                            </div>
                                        </Link>
                                    </Col>
                                )
                            })}
                        </Row>
                     </div>
                    </>:<>
                    <div className="product_wrapper">
                        <Row>
                            <Col lg={3} >
                                {imageTitleDetailsVerticle()}
                            </Col>
                            <Col lg={3} >
                                {imageTitleDetailsVerticle()}
                            </Col>
                            <Col lg={3} >
                                {imageTitleDetailsVerticle()}
                            </Col>
                            <Col lg={3} >
                                {imageTitleDetailsVerticle()}
                            </Col>
                        </Row>
                     </div>
                    </>}
                   
                </div>
            </Container>
        </Fragment>
    )
}
export default  Product;