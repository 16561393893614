import React, {Fragment} from 'react'
import Footer from '../components/Footer/Footer'
import MobileHeader from '../components/MobileHeader/MobileHeader'
import ProductDetails from '../components/ProductDetails/ProductDetails'
import TopNaviation from '../components/TopNavigation/TopNaviation'

const ProductDetailsPage = () =>{
  window.scroll(0,0)

  return (
    <> 
    <MobileHeader/>
    <TopNaviation/>
    <ProductDetails/>
    <Footer/>
   </> 
  )
}
export default ProductDetailsPage