import React, { Fragment, useContext } from "react";
import Banner1 from '../../asset/image/banner1.jpg'
import Banner2 from '../../asset/image/banner2.jpg'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/effect-fade";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay,Pagination,EffectFade } from "swiper";
import { UserContext } from "../../App";
import { baseUrl } from "../../api/Api";
const Banner = () =>{
   const data = useContext(UserContext)
   const{getSliders} = data
   
    return(
        <Fragment>
           <div className="banner">
                <Swiper
                 slidesPerView={1}
                 spaceBetween={30}
                 effect={"fade"}
                 
                 loop={true}
                 pagination={{
                   clickable: true,
                 }}
                 autoplay={{
                 delay: 6500,
                 disableOnInteraction: false,
                    }}
                 modules={[Autoplay,EffectFade,Pagination]}
                 className="mySwiper"
               >
                  {getSliders.map((data,key)=>{
                      const detailsFilter = data.body.replace(/<[^>]*>?/gm, '').slice(0,350)
                      const details = detailsFilter.replace(/\&nbsp;/g, '');
                      const photoUrl = `${baseUrl}/${data.featured_image}`
                     return(
                        <SwiperSlide key={key}> <img src={photoUrl} alt="" />
                           <div className="banner_content">
                                    <h3> {data.title}</h3>
                                    <p>{details}</p>
                           </div>
                        </SwiperSlide>
                     )
                  })}
              </Swiper>    
           </div>
        </Fragment>
    )
}
export default  Banner;