import React, { Fragment } from 'react'
import{ Routes, Route} from 'react-router-dom';
import HomePage from '../pages/HomePage';
import NotFoundPage from '../pages/NotFoundPage';
import ProductDetailsPage from '../pages/ProductDetailsPage';
import ProductListPage from '../pages/ProductListPage';


const AppRouter = () => {
  return (
    <Fragment>
      <Routes >
          <Route path='*' element={<NotFoundPage />} />
          <Route exact path="/" element={<HomePage />}/>
          <Route exact path="/page-two/:cat_id/:ms_id" element={<ProductListPage />}/>
          <Route exact path="/detail/:uuid/:slug" element={<ProductDetailsPage />}/>
      </Routes>
    </Fragment>
  )
}

export default AppRouter