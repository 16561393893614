import { ShimmerPostItem } from "react-shimmer-effects";

export  const imageTitleDetailsVerticle = () => {
  return (
    <div>
      <ShimmerPostItem card title text />
    </div>
  );
};

